<template>
    <module-create-layout>
        <moduleForm
            :dataContainer="dataContainer"
            :edit-mode="false"
            :showPasswordField="true"
            :isForCurrentActiveUser="false"></moduleForm>
    </module-create-layout>
</template>

<script>
    import dataContainer from 'devegram-vue-data-collecter-eui/src/mixins/dataContainer';
    import module from './../index.js'

    export default {
        mixins: [dataContainer],
        components: {
            moduleForm: () => import('./../form/View'),
            moduleCreateLayout: () => import('@/modules-config/layouts/create/View.vue')
        },
        data() {
            return {
                module: {
                    ...module
                },
                dataContainer: this
            }
        }
    }
</script>
